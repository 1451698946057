import React from "react"
import {graphql} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import Breadcrumbs from "../components/Common/Breadcrumbs"

const ServiceDetails = ({ data }) => {
  const parent = [{
    name: "Services",
    link: 'services'
  }];
  const { title, longDescription, image } = data.service
  const imageData = getImage(image);
  return (
    <Layout>
      <Seo title={title} />

      <Navbar />
      {/*<PageBanner pageTitle={title} />*/}
      <div className="pt-120-b-80">
        <div className="container">
          <div className="section-title">
            <h1>{title}</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle={title} parent={parent} />
          <div className="details-content">
            {imageData && (
              <GatsbyImage image={imageData} alt={title} className="details-image" />
            )}
            {/*<img src={image?.childImageSharp.fluid.srcWebp} style={{ float: 'left', margin: '40px' }} alt={title} />*/}
            <ReactMarkdown allowDangerousHtml={true} source={longDescription} />
            <div style={{ clear: "both" }}/>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleServiceDetails($slug: String) {
    service: strapiServices(slug: { eq: $slug }) {
      title
      longDescription
      shortText
      image {
            childImageSharp {
              gatsbyImageData
            }
          }
    }
  }
`;

export default ServiceDetails
